import { Component } from 'inferno';
import formatMoney from './formatMoney';
// import { hardCopyObject, formatCurrency } from './utils'

class MoneyInput extends Component {

  static defaultProps = {
    // name: '',
    // className: '',
    // style: {},
    // currency: 'BRL',
    // currencyConfiguration: hardCopyObject(brlConfiguration),
    // showSymbol: false,
    // spaceSymbol: false,
    onChange: Function.prototype,
  }

  constructor(props) {
		super(props);
		
		const initialState = {
			value: 0,
			maskedValue: formatMoney(0, typeof(this.props.decimals) === 'undefined' ? 2 : props.decimals)
		};

    this.state = Object.assign({}, initialState);
    this.initialState = Object.assign({}, initialState);
  }

  maskValue = (value = 0) => {
    const { decimals } = this.props;

    return formatMoney(value, typeof(decimals) === 'undefined' ? 2 : decimals);
  }

  unmaskValue = (maskedValue = '') => {
    const { decimals } = this.props;
    return parseInt(maskedValue.replace(/\D/g, '') || 0, 10) / (Math.pow(10, typeof(decimals) === 'undefined' ? 2 : decimals));
  }

  handleChange = (event) => {
    const { target } = event;
    const { value: inputValue = 0 } = target;
    const { onChange } = this.props;

    const value = this.unmaskValue(inputValue);
    const maskedValue = this.maskValue(value);

    this.setState({
      value,
      maskedValue,
    });

    if (!onChange || typeof onChange !== 'function') {
      return false;
    }

    return onChange(event, value, maskedValue);
  }

  setInitialValues = () => {
    const { value: givenValue, defaultValue: givenDefaultValue } = this.props;

    const value = givenValue || givenDefaultValue;
    const maskedValue = this.maskValue(value);

    this.setState({
      value,
      maskedValue,
    });
  }

  componentDidUpdate(prevProps) {
    const { defaultValue: prevGivenDefaultValue } = prevProps;
    const { defaultValue: givenDefaultValue } = this.props;

    if (givenDefaultValue !== prevGivenDefaultValue) {
      this.setInitialValues();
    }
  }

  componentDidMount() {
    this.setInitialValues();
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { onChange, decimals, ...props } = this.props;
    const { maskedValue } = this.state;

    return (
      <input
        type="tel"
        {...props}
        value={maskedValue}
        onInput={this.handleChange}
      />
    );
  }
}

export default MoneyInput;
