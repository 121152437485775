import superagent from 'superagent';
import template from 'lodash.template';
import moment from 'moment';

moment.locale('es');

import emailTemplate from './emailTemplate.ejs';
import formatMoney from './formatMoney';

const renderEmailTemplate = template(emailTemplate);

const removeImages = html => html.replace(/^\s+<img (?!alt).*$/gm, '');

const send = ({ event, extraMessage, extraEmails, fechaVencimiento, fechaRetiro, monto, resolucion, attachments, images, customCanal, customTipoCheque }) => {
	
	const req = superagent
		.post(`${BASE_URL}/api/v1/cotizaciones/respuestas/${event._id}`)
		.set('Authorization', `Bearer ${TOKEN}`);
	attachments.forEach(a => req.attach('files', a));
	images.forEach(a => req.attach('images', a));
	return req
		.field('extraMessage', extraMessage)
		.field('extraEmails', extraEmails)
		.field('fechaVencimiento', fechaVencimiento)
		.field('fechaRetiro', fechaRetiro)
		.field('monto', monto)
		.field('resolucion', resolucion)
		.field('customCanal', customCanal || '')
		.field('customTipoCheque', customTipoCheque || '')
		.field('html', removeImages(
			renderEmailTemplate({
				event,
				extraMessage,
				extraEmails,
				fechaVencimiento,
				fechaRetiro,
				monto,
				resolucion,
				formatMoney,
				moment,
				images,
				customCanal,
				customTipoCheque,
			})
		))
		.ok(res => res.status < 400);
};

export default send;