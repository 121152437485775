import { Component, createRef } from 'inferno';
import moment from 'moment';
import template from 'lodash.template';
import formatMoney from './formatMoney';
import emailTemplate from './emailTemplate.ejs';

moment.locale('es');

const renderEmailTemplate = template(emailTemplate);

class Cotizaciones extends Component {
	constructor(props) {
		super(props);

		this.iframe = createRef();
	}

	componentDidUpdate = () => {
		const { options } = this.props;
		if (!options.event) return;
		if (!options.resolucion || options.resolucion === '#') return;
		if (
			options.event.type.split('-')[1] === 'facturas' &&
			(options.resolucion === 'totalidad' || options.resolucion === 'parcial') &&
			options.monto <= 0
		)
			return;
		this.iframe.current.contentDocument.open('about:blank');
		this.iframe.current.contentDocument.write(
			renderEmailTemplate({
				...this.props.options,
				formatMoney,
				moment,
			})
		);
	};

	render({ options }) {
		if (!options.event) {
			return (
				<h2 style={{ margin: '0 auto', 'text-align': 'center' }}>
					Seleccione una cotización primero
				</h2>
			);
		}
		if (!options.resolucion || options.resolucion === '#') {
			return (
				<h2 style={{ margin: '0 auto', 'text-align': 'center' }}>
					Seleccione una resolución primero
				</h2>
			);
		}
		if (
			options.event.type.split('-')[1] === 'facturas' &&
			(options.resolucion === 'totalidad' || options.resolucion === 'parcial') &&
			options.monto <= 0
		) {
			return (
				<h2 style={{ margin: '0 auto', 'text-align': 'center' }}>Ingrese un monto correcto</h2>
			);
		}
		return (
			<iframe
				ref={this.iframe}
				frameBorder="0"
				style={{
					width: '100%',
					height: '100%',
				}}
			/>
		);
	}
}

export default Cotizaciones;
