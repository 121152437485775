import { Component, createRef } from 'inferno';

class SingleDragAndDrop extends Component {

	constructor(props) {
		super(props);
		this.state = { dragging: false };
		this.dropRef = createRef();
		this.dragCounter = 0;
	}

	handleDragIn = e => {
		e.preventDefault();
		e.stopPropagation();
		this.dragCounter++;
		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			this.setState({ dragging: true });
		}
	};

	handleDragOut = e => {
		e.preventDefault();
		e.stopPropagation();
		this.dragCounter = Math.max(this.dragCounter - 1, 0);
		if (this.dragCounter === 0) {
			this.setState({ dragging: false });
		}
	};

	handleDrag = e => {
		e.preventDefault();
		e.stopPropagation();
	};

	handleDrop = e => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({ dragging: false });
		this.dragCounter = 0;
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			this.props && this.props.onHandleDrop(e.dataTransfer.files[0]);
			e.dataTransfer.clearData();
		}
	}

	componentDidMount = () => {
		const div = this.dropRef.current;

		div.addEventListener('dragenter', this.handleDragIn);
		div.addEventListener('dragleave', this.handleDragOut);
		div.addEventListener('dragover', this.handleDrag);
		div.addEventListener('drop', this.handleDrop);
	}

	componentWillUnmount = () => {
		const div = this.dropRef.current;
		div.removeEventListener('dragenter', this.handleDragIn);
		div.removeEventListener('dragleave', this.handleDragOut);
		div.removeEventListener('dragover', this.handleDrag);
		div.removeEventListener('drop', this.handleDrop);
	}

	render = () => {
		const { children, id, onHandleDrop, ...rest } = this.props;
		const { dragging } = this.state;
		return (
			<div ref={this.dropRef} {...rest}>
				{children({ dragging })}
			</div>
		);
	}
}

export default SingleDragAndDrop;
