import { Component } from 'inferno';

import moment from 'moment';
import 'moment/locale/es';

import fetchEvents from './fetch-events';

import Flatpickr from './Flatpickr';
import MoneyInput from './MoneyInput';
import SingleDragAndDrop from './SingleDragAndDrop';

moment.locale('es');

const range = (n, b = 0, fn = v => v) => Array.from({ length: n }).map((_, i) => fn(b + i));

class ChangableField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			state: 'view',
			tempValue: props.value || '#',
		};
	}

	setEditing = (e) => {
		e.preventDefault();
		this.setState({ state: 'editing' });
	}

	handleChange = e => {
		if (e.target.value === '#') return;
		this.setState({ tempValue: e.target.value });
	};

	accept = e => {
		e.preventDefault();
		if (this.state.tempValue === '#') return;
		this.setState({ state: 'view' });
		this.props.onChange && this.props.onChange(this.state.tempValue);
	}

	render({ value, label, options }, { state, tempValue }) {
		if (state === 'view') {
			return (
				<>
					<b>{label}: </b> {value} <a href="#" onClick={this.setEditing}>Cambiar</a>
				</>
			);
		}
		if (state === 'editing') {
			return (
				<>
					<b>{label}: </b> 
					<select onChange={this.handleChange}>
						<option selected={tempValue === '#'} value="#">Seleccionar</option>
						{options.map((option, i) => (
							<option key={i} selected={option === tempValue} value={option}>
								{option}
							</option>
						))}
					</select>
					
					<a href="#" onClick={this.accept}>Aceptar</a>
				</>
			);
		}
		return null;
	}
}

class AssociatePicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			options: [],
			n: '#',
			extraMessage: '',
			extraEmails: '',
			fechaVencimiento: moment().format('DD-MM-YYYY'),
			fechaRetiro: moment().add(1, 'day').format('DD-MM-YYYY HH:mm'),
			monto: 0,
			attachments: [],
			images: [],
			customCanal: null,
			customTipoCheque: null,
		};
	}

	handleChange = key => e => {
		this.setState({ [key]: e.target.value });
		this.props.inform && this.props.inform({ [key]: e.target.value });
	};

	select = event => () => {
		this.props.inform && this.props.inform({ event });
		this.setState({ event });
	};

	seeAll = e => {
		e.preventDefault();
		this.props.inform && this.props.inform({ event: null });
		this.setState({ event: null });
	};

	onKeyDown = e => {
		const { associates } = this.props;
		const { query } = this.state;
		if (e.key !== 'Enter') return;
		e.preventDefault();
		const filteredAssociates = (() => {
			if (query) {
				return associates
					.filter(v => {
						return (
							new RegExp(query, 'i').test(v.razon_social) ||
							new RegExp(query, 'i').test(v.nro_asociado.toString())
						);
					})
					.slice(0, 3);
			}
			return [];
		})();
		if (!filteredAssociates.length) return;
		this.select(filteredAssociates[0])();
	};

	handleDateChange = e => {
		const nextN = e.target.value;
		if (this.state.n !== nextN && nextN !== '#') {
			this.setState({ n: nextN, loading: true, event: null });
			fetchEvents({
				date: moment().subtract(parseInt(nextN, 10), 'days'),
			})
				.then(res => {
					console.log(res.body);
					this.setState({
						loading: false,
						options: res.body,
					});
				})
				.catch(() => {
					this.setState({ loading: false });
					alert('Fallo la conexión con el servidor');
				});
		}
	};

	handleFlatpickrChange = (key, format = 'DD-MM-YYYY') => date => {
		if (date.length) {
			this.setState({ [key]: moment(date[0]).format(format) });
			this.props.inform && this.props.inform({ [key]: moment(date[0]).format(format) });
		}
	};

	handleMoneyChange = key => (e, floatValue, maskedValue) => {
		this.setState({ [key]: floatValue });
		this.props.inform && this.props.inform({ [key]: floatValue });
	};

	handleDrop = (key, preview = false) => file => {
		const self = this;
		const nextAttachments = [...self.state[key], file];
		self.setState({ [key]: nextAttachments });
		if (!preview) {
			return self.props.inform && self.props.inform({ [key]: nextAttachments });
		}

		const reader = new FileReader();
		
		reader.addEventListener("load", function () {
			// convert image file to base64 string
			file.htmlSrc = reader.result;
			self.props.inform && self.props.inform({ [key]: nextAttachments });
		}, false);

    reader.readAsDataURL(file);
	};

	handleRemove = (key, i) => () => {
		const nextAttachments = this.state[key].filter((_, j) => i !== j);
		this.setState({ [key]: nextAttachments });
		this.props.inform && this.props.inform({ [key]: nextAttachments });
	};

	render(
		{ send },
		{
			loading,
			options,
			event,
			resolucion,
			extraMessage,
			extraEmails,
			fechaVencimiento,
			fechaRetiro,
			monto,
			attachments,
			images,
			customCanal,
			customTipoCheque,
		}
	) {
		return (
			<>
				<h4>Seleccione Cotización</h4>
				<select onChange={this.handleDateChange}>
					<option value="#">Seleccionar</option>
					{range(30).map(n => (
						<option key={n} value={n}>
							{moment().subtract(n, 'days').format('dddd DD/MM')}
						</option>
					))}
				</select>
				{loading && <span>Cargando...</span>}
				{!options.length && <span>No hay cotizaciones para el dia seleccionado</span>}
				{options
					.filter(a => (event ? event._id === a._id : true))
					.map(a => (
						<button
							type="button"
							key={a._id}
							onClick={this.select(a)}
							className="button button-outline">
							{a.user
								? `(${a.user.nro_asociado}) ${a.user.razon_social}`
								: `${a.data.body.razon_social} - ${a.data.body.name}`}{' '}
							| {moment(a.created).format('HH:mm')} | {a._id.substr(-6)}
						</button>
					))}
				{event && (
					<a href="#" onClick={this.seeAll} className="text-center block">
						Ver Todas
					</a>
				)}
				{event && (
					<>
						<h4 style={{ 'margin-bottom': '0.5rem' }}>Cotización Seleccionada</h4>
						<b>Tipo: </b> {event.type.split('-')[1]}
						<br />
						<b>Fecha y hora: </b> {moment(event.created).format('dddd DD [de] MMMM HH:mm')}
						<br />
						<b>Asociado: </b>{' '}
						{event.user
							? `(${event.user.nro_asociado}) ${event.user.razon_social}`
							: `${event.data.body.razon_social} - ${event.data.body.name}`}
						<br />
						<b>CUIT: </b> {event.user ? event.user.cuit : event.data.body.cuit}
						<br />
						<ChangableField
							value={customCanal || (event.data.body || {}).canal}
							options={['Cooperativa UNICRED', 'Mercado de Capitales (SGR)']}
							label="Canal"
							onChange={value => this.handleChange('customCanal')({ target: { value } })}
						/>
						<br />
						<ChangableField
							value={customTipoCheque || (event.data.body || {}).tipoCheque}
							options={['Cheque físico', 'e-Cheqs']}
							label="Tipo Cheque"
							onChange={value => this.handleChange('customTipoCheque')({ target: { value } })}
						/>
						<br />
						<b>Emails: </b> {event.data.emails.join(', ')}
						<label style={{ 'font-size': '1.3rem' }} htmlFor="extraEmails">
							Añadir Emails
						</label>
						<textarea
							id="extraEmails"
							placeholder="Añada emails (sin espacios y separados por comas)"
							onKeyUp={this.handleChange('extraEmails')}>
							{extraEmails}
						</textarea>
						<br />
						<b>Adjuntos: </b>
						<ul style={{ 'margin-bottom': '0.5rem' }}>
							{event.data.attachments.map((a, i) => (
								<li key={`${event._id}|${i}`}>
									<a href={a} target="_blank">
										Adjunto #{i + 1}
									</a>
								</li>
							))}
						</ul>
						<h5 style={{ 'margin-bottom': '0.5rem' }}>Respuesta</h5>
						<label style={{ 'font-size': '1.3rem' }} htmlFor="resolucion">
							Resolución
						</label>
						<select id="resolucion" onChange={this.handleChange('resolucion')}>
							<option value="#">Seleccionar</option>
							<option value="totalidad">Operación tomada en su totalidad</option>
							<option value="parcial">Operación tomada parcialmente</option>
							<option value="no-tomada">Operación NO tomada en su totalidad</option>
						</select>
						{event.type.split('-')[1] === 'facturas' &&
							(resolucion === 'totalidad' || resolucion === 'parcial') && (
								<>
									<label style={{ 'font-size': '1.3rem' }} htmlFor="fechaVencimiento">
										Fecha Vencimiento
									</label>
									<Flatpickr
										id="fechaVencimiento"
										value={fechaVencimiento}
										options={{
											dateFormat: 'd-m-Y',
										}}
										onChange={this.handleFlatpickrChange('fechaVencimiento')}
									/>
									<label style={{ 'font-size': '1.3rem' }} htmlFor="monto">
										Monto
									</label>
									<MoneyInput
										className="text-right"
										placeholder="Monto"
										decimals={0}
										value={monto}
										onChange={this.handleMoneyChange('monto')}
									/>
									<label style={{ 'font-size': '1.3rem' }} htmlFor="fechaRetiro">
										Fecha Retiro
									</label>
									<Flatpickr
										id="fechaRetiro"
										value={fechaRetiro}
										options={{
											dateFormat: 'd-m-Y H:i',
											enableTime: true,
										}}
										onChange={this.handleFlatpickrChange('fechaRetiro', 'DD-MM-YYYY HH:mm')}
									/>
								</>
							)}
						<label style={{ 'font-size': '1.3rem' }} htmlFor="extraMessage">
							Añadir Mensaje
						</label>
						<textarea
							id="extraMessage"
							placeholder="Añadir mensaje"
							onKeyUp={this.handleChange('extraMessage')}>
							{extraMessage}
						</textarea>
						<label style={{ 'font-size': '1.3rem' }}>Archivos adjuntos</label>
						<span>Maximo 3 archivos</span>
						<SingleDragAndDrop onHandleDrop={this.handleDrop('attachments')}>
							{({ dragging }) => (
								<div className={['dropzone', dragging && 'active'].filter(Boolean).join(' ')}>
									{attachments.length ? null : <span>Arrastre los archivos aqui</span>}
									{attachments.map((a, i) => (
										<div key={a.name}>
											<span>{a.name}</span>
											&nbsp;
											<span className="remove" onClick={this.handleRemove('attachments', i)}>
												&times;
											</span>
										</div>
									))}
								</div>
							)}
						</SingleDragAndDrop>
						<label style={{ 'font-size': '1.3rem' }}>Imagenes en el cuerpo</label>
						<span>Maximo 3 imagenes</span>
						<SingleDragAndDrop onHandleDrop={this.handleDrop('images', true)}>
							{({ dragging }) => (
								<div className={['dropzone', dragging && 'active'].filter(Boolean).join(' ')}>
									{images.length ? null : <span>Arrastre las imagenes aqui</span>}
									{images.map((a, i) => (
										<div key={a.name}>
											<span>{a.name}</span>
											&nbsp;
											<span className="remove" onClick={this.handleRemove('images', i)}>
												&times;
											</span>
										</div>
									))}
								</div>
							)}
						</SingleDragAndDrop>
						<button
							className="button button-outline"
							onClick={send}
							disabled={
								!(
									resolucion &&
									(event.type.split('-')[1] === 'facturas'
										? resolucion === 'totalidad' || resolucion === 'parcial'
											? monto > 0
											: true
										: true)
								)
							}>
							Enviar respuesta ahora
						</button>
					</>
				)}
			</>
		);
	}
}

export default AssociatePicker;
