import CotizacionPicker from './CotizacionPicker';
import Cotizaciones from './Cotizaciones';


const Layout = ({ options, inform, send, }) => (
	<div id="app">
		<div className="pane left">
			<CotizacionPicker inform={inform} send={send} />
		</div>
		<div className="pane right">
			<Cotizaciones options={options} />
		</div>
	</div>
);

export default Layout;