import { Component } from 'inferno';

import moment from 'moment';

import Layout from './Layout';

import send from './send-email';

const boot = () => {
	return Promise.resolve();
};

class App extends Component {

	constructor() {
		super();

		const lsPassword = localStorage.getItem('unicred:loggedIn');
		const loggedIn = (() => {
			if (!lsPassword) return false;
			const time = parseInt(lsPassword, 10);
			if (!time) return false;
			return time > Date.now();
		})();

		if (!loggedIn) {
			localStorage.removeItem('unicred:loggedIn');
		}

		this.state = {
			loading: true,
			loggedIn,
			password: '',
			options: {
				extraMessage: '',
				extraEmails: '',
				fechaVencimiento: moment().format('DD-MM-YYYY'),
				fechaRetiro: moment().add(1, 'day').format('DD-MM-YYYY HH:mm'),
				monto: 0,
				attachments: [],
				images: [],
				customCanal: null,
				customTipoCheque: null,
			}
		};

		this.handleInform = this.handleInform.bind(this);
	}

	componentWillMount() {

		boot().then(() => {
			this.setState({
				loading: false,
			});
		})
		.catch(err => {
			console.error(err);
			alert('No se pudo contactar al servidor');
		});
	}

	handleInform = options => {
		this.setState({
			options: Object.assign({}, this.state.options, options)
		});
	}

	handleChange = key => e => this.setState({ [key]: e.target.value });

	send = () => {
		const { options } = this.state;
		if (!confirm(`Esta seguro que desea realizar este envio?`)) return;
		send(options).then(() => {
			alert('Se ha enviado exitosamente');
		}, err => {
			console.error(err);
			alert('Ha ocurrido un error en el envio. Intente nuevamente');
		});
	}

	submitPassword = e => {
		e.preventDefault();
		if (this.state.password === PASSWORD) {
			localStorage.setItem('unicred:loggedIn', Date.now() + 1 * 30 * 24 * 60 * 60 * 1000);
			this.setState({ loggedIn: true });
		}
	}

	render(_, { loading, options, loggedIn, password, }) {
		const content = (() => {
			if (loading) return <h1 className="loading">Cargando...</h1>;
			if (!loggedIn) {
				return (
					<div id="app">
						<div className="login-container">
							<img src="https://unicred.com.ar/dist/images/logo-unicred-web-secundario.png" alt="Logo Unicred" />
							<form onSubmit={this.submitPassword}>
								<label htmlFor="password">Contraseña</label>
								<input placeholder="Contraseña" id="password" type="password" value={password} onInput={this.handleChange('password')} />
								<button type="submit">Ingresar</button>
							</form>
						</div>
					</div>
				);
			}
			return (
				<Layout
					options={options}
					inform={this.handleInform}
					send={this.send}
				/>
			);
		})();
		return content;
	}
}


export default App;