import superagent from 'superagent';
import moment from 'moment';

const fetchEvents = ({ date }) => {
	return superagent
		.get(`${BASE_URL}/api/v1/events`)
		.query({
			query: JSON.stringify({
				type: {
					$in: ['cotizacion-cheques', 'cotizacion-facturas']
				},
				created: {
					$gte: +moment(date).startOf('day'),
					$lte: +moment(date).endOf('day'),
				}
			}),
			select: JSON.stringify({
				type: 1,
				user: 1,
				'data.attachments': 1,
				'data.body.atendido_por': 1,
				'data.body.canal': 1,
				'data.body.cuit': 1,
				'data.body.email': 1,
				'data.body.name': 1,
				'data.body.razon_social': 1,
				'data.body.tipoCheque': 1,
				'data.emails': 1,
				created: 1,
			}),
			populate: JSON.stringify([{
				path: 'user',
				select: 'nro_asociado razon_social cuit'
			}])
		})
		.set('Authorization', `Bearer ${TOKEN}`)
		.ok(res => res.status < 400);
};

export default fetchEvents;